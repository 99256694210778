<template>
  <div :class="{'row flex-wrap': !!(formColumns)}">
    <div v-for="form in forms" :key="form.name" class="mb-5" :class="{[rowClass]: !!(formColumns)}">
      <label v-if="form.type !== 'BOOLEAN'">{{`${form.label ? form.label : $filters.convertFromCamelCase(form.name)}${form.required ? ' *' : ''}`}}</label>
      <div class="mad-lib-container">
        <mad-lib-select v-if="form.type === 'SELECT'"
                        v-model="valueCopy[form.name]"
                        :options="form.options" />
        <b-checkbox v-else-if="form.type === 'BOOLEAN'"
                    :checked="valueCopy[form.name]"
                    switch
                    @change="valueCopy[form.name] = !valueCopy[form.name]">
          {{`${form.label ? form.label : $filters.convertFromCamelCase(form.name)}${form.required ? ' *' : ''}`}}
        </b-checkbox>
        <basic-mad-lib-input v-else
                             v-model="valueCopy[form.name]"
                             v-bind="form.inputProps || {}"
                             :format="form.type" />
      </div>
      <small v-if="form.description" class="text-muted">{{form.description}}</small>
    </div>
  </div>
</template>
<script>
import MadLibSelect from './madLibSelect'
import BasicMadLibInput from './basicMadLibInput'
export default {
  name: 'promptForm',
  components: { BasicMadLibInput, MadLibSelect },
  props: {
    value: {
      type: Object
    },
    forms: {
      type: Array
    },
    formColumns: {
      type: Number
    }
  },
  data () {
    return {
      valueCopy: { ...this.value }
    }
  },
  computed: {
    rowClass () {
      if (this.formColumns) {
        return `col-${12 / this.formColumns}`
      }
      return ''
    }
  },
  watch: {
    valueCopy: {
      deep: true,
      handler (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
